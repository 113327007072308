<template>
  <v-dialog v-model="showModal" max-width="1200px" persistent>
    <v-card>
      <v-card-title class="text-h6 white--text px-2 py-1 fixed-header" :class="titleClass">
        会員名：{{ memberInfo?.member_name_abbr ?? '' }}
      </v-card-title>
      <span v-if="higherInsuranceLimit" class="red--text pl-4">●FedExの補償価格が5万ドルを超えてます!</span>

      <!-- 送付先 -->
      <v-card-actions class="px-0 d-flex justify-center">
        <v-radio-group
          class="my-0 px-4"
          v-model="shipToTypeNo"
          dense
          :column="false"
          hide-details
          :disabled="showConfirm"
          @change="updateAddr"
        >
          <v-radio
            v-for="(label, index) in shipToTypeLabel"
            :key="index"
            :label="+memberInfo?.default_ship_to === index + 1 ? label + '（会員デフォルト送付先）' : label"
            :value="index + 1"
            :disabled="label === '一時変更' && memberInfo ? !memberInfo.exists_tmp_changed_ship_to : false"
          ></v-radio>
        </v-radio-group>
      </v-card-actions>
      <div>
        <v-card-actions v-if="shipToTypeNo === 5" class="px-0 d-flex justify-center">
          <v-btn-toggle
            v-model="btnToggle"
            color="primary"
            class="px-1"
            @change="
              country = null;
              state = null;
              btnToggle >= 0 ? pasteAddr(btnToggle + 1) : void 0;
            "
          >
            <v-btn v-for="n in 3" :key="n" x-small text><span>送付先</span>{{ n }}</v-btn>
          </v-btn-toggle>
          <span class="caption">を貼り付ける</span>
        </v-card-actions>
        <!-- クーリエ情報 -->
        <v-card-subtitle class="py-0 my-0">クーリエ情報</v-card-subtitle>
        <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
          <!-- クーリエ/クーリエサービス名/補償 -->
          <v-row no-gutters>
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">{{ courierInfoLabel[0] }}</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-select
                class="align-self-center mr-2"
                style="max-width: 150px"
                hide-details
                outlined
                dense
                v-model="courierCode"
                :items="couriers"
                item-text="name"
                item-value="code"
                :rules="rules"
                :disabled="disabled"
                @change="changeCourier"
              ></v-select>
              <v-select
                class="align-self-center mr-2"
                v-model="deliveryType"
                hide-details
                outlined
                dense
                :items="courierDeliveryTypes[courierCode]"
                item-text="name"
                item-value="value1"
                :rules="rules"
                :disabled="disabled"
              ></v-select>
              <v-select
                v-model="insurance"
                class="align-self-center mr-2"
                style="max-width: 150px"
                hide-details
                outlined
                dense
                :items="insuranceItems"
                item-value="code"
                item-text="name"
                :disabled="disabled"
              ></v-select>
            </v-col>
          </v-row>
          <!-- 送料負担者/アカウントNo. -->
          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">{{ courierInfoLabel[1] }}</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-select
                v-model="shippingFeePayer"
                class="align-self-center mr-2"
                style="max-width: 150px"
                hide-details
                outlined
                dense
                :items="shippingFeePayers"
                item-text="name"
                item-value="code"
                :disabled="disabled"
                @change="changeShippingFeePayer"
              ></v-select>
              <v-text-field
                v-if="shippingFeePayer == 2 && courierCode === 2"
                class="mr-2 align-self-center"
                v-model="thirdPartyAccInfo.account"
                style="max-width: 150px"
                label="アカウントNo"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled"
                @input="shippingFeePayerAcc = thirdPartyAccInfo.account"
              ></v-text-field>
              <v-text-field
                v-else
                class="mr-2 align-self-center"
                v-model="shippingFeePayerAcc"
                style="max-width: 150px"
                label="アカウントNo"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled || shippingFeePayer == 0"
              ></v-text-field>
              <v-text-field
                v-if="shippingFeePayer == 2 && courierCode === 2"
                class="mr-2 align-self-center"
                v-model="thirdPartyAccInfo.account_holder_company_name"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled"
                @input="shippingFeePayerCompanyName = thirdPartyAccInfo.account_holder_company_name"
              ></v-text-field>
              <v-text-field
                v-else
                class="mr-2 align-self-center"
                v-model="shippingFeePayerCompanyName"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled || shippingFeePayer == 0"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- 関税・諸経費負担者/アカウントNo -->
          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">{{ courierInfoLabel[2] }}</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-select
                v-model="accountHolder"
                class="align-self-center mr-2"
                style="max-width: 150px"
                hide-details
                outlined
                dense
                :items="taxPayerTypes"
                item-text="holder"
                item-value="code"
                :disabled="disabled"
                @change="changeTaxPayer"
              ></v-select>
              <v-text-field
                v-if="accountHolder == 1 && courierCode === 2"
                class="mr-2 align-self-center"
                v-model="thirdPartyAccInfo.account"
                style="max-width: 150px"
                label="アカウントNo"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled"
                @input="taxPayerAccNo = thirdPartyAccInfo.account"
              ></v-text-field>
              <v-text-field
                v-else
                class="mr-2 align-self-center"
                v-model="taxPayerAccNo"
                style="max-width: 150px"
                label="アカウントNo"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled"
              ></v-text-field>
              <v-text-field
                v-if="accountHolder == 1 && courierCode === 2"
                class="mr-2 align-self-center"
                v-model="thirdPartyAccInfo.account_holder_company_name"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled"
                @input="taxPayerCompanyName = thirdPartyAccInfo.account_holder_company_name"
              ></v-text-field>
              <v-text-field
                v-else
                class="mr-2 align-self-center"
                v-model="taxPayerCompanyName"
                outlined
                dense
                :hide-details="true"
                :rules="rules"
                single-line
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- 荷受人アカウントVAT Number -->
          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">{{ courierInfoLabel[4] }}</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-text-field
                class="mr-2 align-self-center"
                v-model="vatNumber"
                style="max-width: 300px"
                label="VAT No."
                outlined
                dense
                :hide-details="true"
                single-line
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- 運送条件 -->
          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">{{ courierInfoLabel[3] }}：</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-select
                class="align-self-center mr-2"
                style="max-width: 300px; min-width: 250px"
                v-model="shippingTerm"
                :items="shippingTerms"
                item-text="name"
                item-value="code"
                :label="courierInfoLabel[3]"
                hide-details
                outlined
                dense
                :disabled="disabled"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>

        <!-- 出荷時情報-->
        <v-card-subtitle class="py-0 my-0">出荷時情報</v-card-subtitle>
        <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
          <!-- PO Number -->
          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">PO Number：</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-text-field
                class="mr-2 align-self-center"
                v-model="poNumberList"
                style="max-width: 300px"
                label="PO No."
                outlined
                dense
                :hide-details="true"
                single-line
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Reference：</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-text-field
                class="mr-2 align-self-center"
                style="max-width: 300px"
                label="Reference"
                v-model="reference"
                outlined
                dense
                :hide-details="true"
                single-line
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-1">
            <v-col class="d-flex pb-0" cols="12" :sm="sm1">
              <v-card-text class="text-start text-sm-end align-self-center">Description</v-card-text>
            </v-col>
            <v-col class="d-flex pb-0" cols="12" :sm="sm2">
              <v-text-field
                class="mr-2 align-self-center"
                label="Description"
                v-model="description"
                outlined
                dense
                :hide-details="true"
                single-line
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>

        <!-- 住所 -->
        <v-card-subtitle class="pt-3 pb-0 my-0">送付先住所</v-card-subtitle>
        <v-card class="mx-4 my-1 pt-2" outlined elevation="0">
          <v-form ref="form" v-model="validate" lazy-validation>
            <!-- 会社名 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">会社名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  class="mr-2 align-self-center"
                  v-model="shipToCompanyName"
                  label="会社名"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 国名 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">国名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-autocomplete
                  v-if="!disabled"
                  class="align-self-center mr-2"
                  label="国名"
                  hide-details
                  single-line
                  outlined
                  dense
                  v-model="country"
                  :items="courierCountries"
                  :rules="rules"
                  @change="state = null"
                >
                </v-autocomplete>
                <v-text-field
                  v-else
                  class="mt-2 mr-2 mt-sm-2"
                  label="国名"
                  outlined
                  dense
                  :hide-details="true"
                  :value="computedCountryName"
                  single-line
                  disabled
                ></v-text-field>
                <v-text-field
                  class="mt-2 mr-2 mt-sm-2"
                  style="max-width: 100px"
                  outlined
                  dense
                  :hide-details="true"
                  single-line
                  :value="countryCode"
                  :rules="rules"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 州名 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">州名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-autocomplete
                  v-if="!disabled && hasStates"
                  class="align-self-center mr-2"
                  label="州名"
                  hide-details
                  single-line
                  outlined
                  dense
                  v-model="state"
                  :items="courierStates"
                  :rules="rules"
                  :disabled="disabled && !hasStates"
                ></v-autocomplete>
                <v-text-field
                  v-else
                  label="州名"
                  class="mt-1 mr-2 mt-sm-2"
                  outlined
                  dense
                  :hide-details="true"
                  :value="computedStateName"
                  single-line
                  disabled
                ></v-text-field>
                <v-text-field
                  class="mt-1 mr-2 mt-sm-2"
                  style="max-width: 100px"
                  outlined
                  dense
                  :hide-details="true"
                  single-line
                  :value="stateCode"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 都市名/郵便番号 -->
            <v-row no-gutters class="mt-1">
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">都市名/郵便番号：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="city"
                  class="mr-2 align-self-center"
                  label="都市名"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :rules="rules"
                  :disabled="disabled"
                ></v-text-field>
                <v-text-field
                  v-model="zipCode"
                  class="mr-2 align-self-center"
                  style="max-width: 200px"
                  label="郵便番号"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 住所１ -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">住所１：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="addr1"
                  class="mr-2 align-self-center"
                  label="住所１"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :rules="rules"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 住所2 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">住所２：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="addr2"
                  class="mr-2 align-self-center"
                  label="住所２"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- TEL -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">TEL：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="tel"
                  class="mr-2 align-self-center"
                  label="TEL"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :rules="telRules"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 担当者名 -->
            <v-row no-gutters class="mb-1">
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end">担当者名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="personInCharge"
                  class="mr-2 align-self-center"
                  label="担当者名"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :rules="rules"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>

        <!-- 第三者アカウント詳細情報 -->
        <v-card-subtitle
          v-show="(shippingFeePayer == 2 || accountHolder == 1) && courierCode === 2"
          class="pt-3 pb-0 my-0"
          >第三者アカウント詳細情報</v-card-subtitle
        >
        <v-card
          v-show="(shippingFeePayer == 2 || accountHolder == 1) && courierCode === 2"
          class="mx-4 my-1 pt-2"
          outlined
          elevation="0"
        >
          <v-form>
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">アカウント：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-text-field
                  class="mr-2 align-self-center"
                  v-model="thirdPartyAccInfo.account"
                  style="max-width: 150px"
                  label="アカウントNo"
                  outlined
                  dense
                  :hide-details="true"
                  single-line
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 会社名 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">会社名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  class="mr-2 align-self-center"
                  v-model="thirdPartyAccInfo.account_holder_company_name"
                  label="会社名"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 国名 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">国名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-autocomplete
                  v-if="!disabled"
                  class="align-self-center mr-2"
                  label="国名"
                  hide-details
                  single-line
                  outlined
                  dense
                  v-model="thirdPartyAccInfo.account_holder_country"
                  :items="courierCountries"
                  @change="thirdPartyAccInfo.account_holder_state = null"
                >
                </v-autocomplete>
                <v-text-field
                  v-else
                  class="mt-2 mr-2 mt-sm-2"
                  label="国名"
                  outlined
                  dense
                  :hide-details="true"
                  :value="thirdPartyAccInfo.account_holder_country"
                  single-line
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 州名 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">州名：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-autocomplete
                  v-if="!disabled && courierStatesThirdParty?.length"
                  class="align-self-center mr-2"
                  label="州名"
                  hide-details
                  single-line
                  outlined
                  dense
                  v-model="thirdPartyAccInfo.account_holder_state"
                  :items="courierStatesThirdParty"
                  :disabled="disabled && courierStatesThirdParty?.length === 0"
                ></v-autocomplete>
                <v-text-field
                  v-else
                  label="州名"
                  class="mt-1 mr-2 mt-sm-2"
                  outlined
                  dense
                  :hide-details="true"
                  :value="thirdPartyAccInfo.account_holder_state"
                  single-line
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 都市名/郵便番号 -->
            <v-row no-gutters class="mt-1">
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">都市名/郵便番号：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="thirdPartyAccInfo.account_holder_city"
                  class="mr-2 align-self-center"
                  label="都市名"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
                <v-text-field
                  v-model="thirdPartyAccInfo.account_holder_zip_code"
                  class="mr-2 align-self-center"
                  style="max-width: 200px"
                  label="郵便番号"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 住所１ -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">住所１：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="thirdPartyAccInfo.account_holder_address1"
                  class="mr-2 align-self-center"
                  label="住所１"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- 住所2 -->
            <v-row no-gutters>
              <v-col class="d-flex pb-0" cols="12" :sm="sm1">
                <v-card-text class="text-start text-sm-end align-self-center">住所２：</v-card-text>
              </v-col>
              <v-col class="d-flex pb-0" cols="12" :sm="sm2">
                <v-text-field
                  v-model="thirdPartyAccInfo.account_holder_address2"
                  class="mr-2 align-self-center"
                  label="住所２"
                  single-line
                  outlined
                  dense
                  :hide-details="true"
                  :disabled="disabled"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>

        <!-- 補償計算 -->
        <v-card-subtitle v-show="insurance == 1" class="pt-3 pb-0 my-0">補償</v-card-subtitle>
        <v-card v-show="insurance == 1" class="mx-4 my-1" outlined elevation="0" :disabled="shipToTypeNo !== 5">
          <v-form>
            <v-row no-gutters>
              <v-radio-group v-model="insuranceCalculation1" dense hide-details :disabled="showConfirm">
                <v-radio class="ml-4" color="primary" :value="true"></v-radio>
              </v-radio-group>
              <v-col cols="12" sm="2" class="d-flex flex-column">
                <v-text-field
                  class="mx-4 mt-2 mb-1 text-center"
                  label="invoice価格(USD)"
                  :hide-details="true"
                  dense
                  outlined
                  filled
                  :value="memberInfo?.total_price ? memberInfo?.total_price?.commaString() : 0"
                  readonly
                ></v-text-field>
              </v-col>
              <label class="align-self-center">x</label>
              <v-col cols="12" sm="2" class="d-flex flex-column">
                <v-text-field
                  class="mx-4 mt-2 mb-1 text-center"
                  label="補償範囲(%)"
                  :hide-details="true"
                  dense
                  outlined
                  persistent-placeholder
                  placeholder="100%"
                  :rules="[(v) => v >= 0 && v <= 100]"
                  :readonly="!insuranceCalculation1"
                  :filled="!insuranceCalculation1 || showConfirm"
                  type="number"
                  :value="Math.trunc(percent)"
                  @input="
                    percent = $event;
                    insurancePrice =
                      courierCode == 1
                        ? +((memberInfo.total_price * $event) / 100).toFixed(2)
                        : +(((memberInfo.total_price * $event) / 100) * rate).toFixed(0);
                  "
                ></v-text-field>
              </v-col>
              <label v-if="courierCode !== 1" class="align-self-center">x</label>
              <v-col v-if="courierCode !== 1" cols="12" sm="2" class="d-flex flex-column">
                <v-text-field
                  class="mx-4 mt-2 mb-1 text-center"
                  label="レート"
                  :hide-details="true"
                  dense
                  outlined
                  filled
                  :value="(shipTo && shipTo.sent_to_courier_ship_api === 1 ? shipTo.jpy_exchange_rate : null) || rate"
                  readonly
                ></v-text-field>
              </v-col>
              <label class="align-self-center">=</label>
              <v-col cols="12" sm="2" class="d-flex flex-column">
                <v-text-field
                  v-if="courierCode != 1"
                  class="mx-4 mt-2 mb-1 text-center"
                  :label="`補償価格(${courierCode == 1 ? 'USD' : 'JPY'})`"
                  :hide-details="true"
                  dense
                  outlined
                  filled
                  :value="insurancePriceByCourier?.commaString()"
                  @input="setInsurancePrice"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-4 mt-2 mb-1 text-center"
                  :label="`補償価格(${courierCode == 1 ? 'USD' : 'JPY'})`"
                  :hide-details="true"
                  dense
                  outlined
                  filled
                  :value="insurancePriceByCourier?.commaString()"
                  @input="setInsurancePrice"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-radio-group v-model="insuranceCalculation2" dense hide-details :disabled="showConfirm">
                <v-radio class="ml-4" color="primary" :value="true"></v-radio>
              </v-radio-group>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-if="courierCode !== 1"
                  class="mx-4 mt-2 mb-1 text-center"
                  :label="`補償価格(${courierCode == 1 ? 'USD' : 'JPY'})`"
                  :hide-details="true"
                  dense
                  outlined
                  :readonly="!insuranceCalculation2 || methodFlag === 2"
                  :filled="!insuranceCalculation2 || methodFlag === 2"
                  :value="
                    !insuranceCalculation2
                      ? ''
                      : shipToTypeNo != 5
                        ? Number(insurancePrice.toFixed(0))?.commaString()
                        : insurancePrice.toFixed(0)
                  "
                  @input="setInsurancePrice"
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-4 mt-2 mb-1 text-center"
                  :label="`補償価格(${courierCode == 1 ? 'USD' : 'JPY'})`"
                  :hide-details="true"
                  dense
                  outlined
                  :readonly="!insuranceCalculation2 || methodFlag === 2"
                  :filled="!insuranceCalculation2 || methodFlag === 2"
                  :value="
                    !insuranceCalculation2 ? '' : shipToTypeNo != 5 ? insurancePrice?.commaString() : insurancePrice
                  "
                  @input="setInsurancePrice"
                ></v-text-field>
                <v-card-subtitle
                  v-show="insuranceCalculation2 && shipToTypeNo == 5"
                  :class="`ml-3 my-0 py-0 ${higherInsuranceLimit ? 'red--text' : ''}`"
                  >{{ courierCode == 1 ? insurancePrice?.commaString() : Math.round(insurancePrice)?.commaString() }}
                  {{ courierCode == 1 ? 'USD' : 'JPY' }}</v-card-subtitle
                >
              </v-col>
            </v-row>
            <v-card-text v-show="higherInsuranceLimit" class="pt-0 red--text"
              >●FedExの補償価格が5万ドルを超えてます</v-card-text
            >
          </v-form>
        </v-card>
      </div>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          v-if="methodFlag === 1"
          color="primary"
          :disabled="disableBtn || showConfirm || loading || higherInsuranceLimit"
          :loading="loading"
          @click="handleChange"
        >
          変更
        </v-btn>
        <v-btn
          v-if="methodFlag === 2"
          color="primary"
          :disabled="disableBtn || loading || higherInsuranceLimit"
          :loading="loading"
          @click="handleSend"
          >確認
        </v-btn>
        <v-btn
          v-if="methodFlag === 3"
          color="primary"
          :disabled="disableBtn || loading"
          :loading="loading"
          @click="handleSend"
          >チェック完了
        </v-btn>
        <v-btn color="orange darken-1" text :disabled="loading" @click="handleClose"> キャンセル </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    memberInfo: {
      type: Object,
      required: true,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    methodFlag: {
      default: 1,
    },
  },
  emits: ['change:radio', 'close', 'click:confirm'],
  data() {
    return {
      shippingTerm: '',
      thirdPartyAccInfo: {},
      shipToCompanyName: '',
      taxPayerAccNo: '',
      shippingFeePayerAcc: '',
      shippingFeePayerCompanyName: '',
      taxPayerCompanyName: '',
      vatNumber: '',
      poNumberList: '',
      reference: '',
      description: '',
      courierCode: 1,
      couriers: [
        { code: 1, name: 'FedEx' },
        { code: 2, name: 'UPS' },
        { code: 3, name: 'DHL' },
      ],
      shippingFeePayer: 0,
      deliveryType: '1',
      country: null,
      state: null,
      city: '',
      zipCode: '',
      addr1: '',
      addr2: '',
      tel: '',
      personInCharge: '',
      insuranceItems: [
        { code: 0, name: '補償なし' },
        { code: 1, name: '補償あり' },
      ],
      insurance: null,
      insurancePrice: 0,
      accountHolder: null,
      shipToTypeNo: this.memberInfo?.default_ship_to ?? 1,
      shipToTypeLabel: ['送付先１', '送付先２', '送付先３', '一時変更', '手動入力'],
      courierInfoLabel: [
        'クーリエ/クーリエサービス名/補償：',
        '送料負担者／アカウントNo.：',
        '関税・諸経費負担者/アカウントNo：',
        '運送条件',
        '荷受人アカウント VAT No：',
      ],
      rules: [(v) => !!v || '必要'],
      telRules: [(v) => !!v || '必要', (v) => v >= 0],
      validate: false,
      sm1: 3,
      sm2: 9,
      insuranceCalculation1: true,
      insuranceCalculation2: false,
      percent: 100,
      btnToggle: null,
    };
  },
  computed: {
    ...mapGetters({
      courierCountriesStates: 'constantMaster/courierCountriesStates',
      courierDeliveryTypes: 'constantMaster/courierDeliveryTypes',
      shippingTerms: 'constantMaster/shippingTerms',
      descriptionsByCourier: 'constantMaster/descriptionByCourier',
      shipTo: 'shukkaShoruiSakusei/shipTo',
      rate: 'shukkaShoruiSakusei/rate',
      loading: 'ui/loading',
    }),
    descriptionString() {
      const rank = {
        pm: 'ppm',
        p: 'ppm',
        'p-': 'ppm',
      };

      const deviceRank =
        this.memberInfo?.rank
          .toLowerCase()
          .split(',')
          .find((el) => Object.keys(rank).includes(el)) ?? this.memberInfo?.rank;

      const grade = rank[deviceRank] ?? 'normal';

      const isPC = this.shipTo.has_pc === 1;
      return (
        this.descriptionsByCourier
          .map(({ value1: rank, value2: description, value3: courierCode }) =>
            this.courierCode == 3 && isPC && rank === 'pc' // DHL and has PC
              ? { rank, description, courierCode }
              : this.courierCode == courierCode && grade === rank // Other than PC
                ? { rank, description, courierCode }
                : null,
          )
          .filter(Boolean)
          .find(({ rank, courierCode }) =>
            this.courierCode == 3 && isPC
              ? rank === 'pc' && courierCode == 3
              : courierCode == this.courierCode && rank === grade,
          )?.description ?? null
      );
    },
    shippingFeePayers() {
      if (this.shipToTypeNo === 5) {
        return [
          { code: 0, name: '荷送人' },
          { code: 1, name: '荷受人' },
          { code: 2, name: '第三者' },
        ];
      }
      if (this.shipTo && this.shipTo.account_holder === 0) {
        return [
          { code: 0, name: '荷送人' },
          { code: 1, name: '荷受人' },
        ];
      } else if (this.shipTo && this.shipTo.account_holder === 1) {
        return [
          { code: 0, name: '荷送人' },
          { code: 2, name: '第三者' },
        ];
      } else
        return [
          { code: 0, name: '荷送人' },
          { code: 1, name: '荷受人' },
          { code: 2, name: '第三者' },
        ];
    },
    taxPayerTypes() {
      if (this.shipToTypeNo === 5) {
        return [
          { code: 0, holder: '荷受人' },
          { code: 1, holder: '第三者' },
        ];
      } else if (this.shipTo && this.shipTo.account_holder === 0) {
        return [{ code: 0, holder: '荷受人' }];
      } else if (this.shipTo && this.shipTo.account_holder === 1) {
        return { code: 1, holder: '第三者' };
      } else
        return [
          { code: 0, holder: '荷受人' },
          { code: 1, holder: '第三者' },
        ];
    },
    insurancePriceByCourier() {
      const insuredAmount = +this.memberInfo?.total_price * /* this.percent === 0 ? 100 :  */ (this.percent / 100) ?? 0;
      // UPS and DHL
      if (this.courierCode !== 1) {
        const rate = this.shipTo?.sent_to_courier_ship_api === 1 ? this.shipTo.jpy_exchange_rate : this.rate;
        return +(insuredAmount * rate).toFixed(0);
      }
      // FEDEX
      return +insuredAmount.toFixed(2);
    },
    courierCountries() {
      return this.courierCountriesStates
        ? this.courierCountriesStates[this.courierCode ?? 1].countryLists.map(({ name }) => name)
        : [];
    },
    courierStates() {
      return (
        this.courierCode &&
        this.courierCountriesStates?.[this.courierCode].stateLists
          .map(({ country, name }) => {
            if (country === this.countryCode) {
              return name;
            }
          })
          .filter((x) => x)
      );
    },
    courierStatesThirdParty() {
      return (
        this.courierCode &&
        this.courierCountriesStates?.[this.courierCode].stateLists
          .map(({ country, name }) => {
            if (country === this.countryCodeThirdParty) {
              return name;
            }
          })
          .filter((x) => x)
      );
    },
    computedCountryName() {
      const country =
        this.courierCountriesStates && this.courierCode
          ? this.courierCountriesStates[this.courierCode > 3 ? 1 : this.courierCode].countryLists.find(
              ({ code }) => code === this.countryCode,
            )
          : null;
      return country ? country.name : country;
    },
    computedStateName() {
      if (!this.courierCountriesStates || !this.courierCode || !this.countryCode) return '';
      const state = this.courierCountriesStates[this.courierCode].stateLists.find(({ country, code }) => {
        if (country !== this.countryCode) return false;
        return code === this.stateCode;
      });
      return state?.name;
    },
    countryCode() {
      if (this.shipTo && this.shipToTypeNo !== 5) {
        return this.shipTo ? this.shipTo.ship_to_country_code : null;
      } else if (this.shipTo && this.shipToTypeNo === 5) {
        const countryInfo = this.courierCode
          ? this.courierCountriesStates?.[this.courierCode]?.countryLists?.find(({ name }) => name === this.country)
          : null;
        return countryInfo ? countryInfo.code || this.shipTo.ship_to_country_code : this.shipTo.ship_to_country_code;
      }
      return null;
    },
    countryCodeThirdParty() {
      const found = this.courierCountriesStates?.[this.courierCode]?.['countryLists']?.find(
        ({ country }) => country === this.thirdPartyAccInfo.account_holder_country,
      );
      return found?.code ?? this.thirdPartyAccInfo.account_holder_country_code;
    },
    stateCode() {
      if (this.shipTo && this.shipToTypeNo !== 5) {
        return this.shipTo?.ship_to_state_code;
      } else if (this.shipTo && this.shipToTypeNo === 5) {
        const stateInfo =
          this.courierCountriesStates && this.courierCode
            ? this.courierCountriesStates[this.courierCode].stateLists.find(({ name }) => name === this.state)
            : null;
        return stateInfo && this.state
          ? stateInfo.code
          : this.shipTo.ship_to_country_code !== this.countryCode && !this.state
            ? stateInfo
            : this.shipTo.ship_to_state_code;
      }
      return null;
    },
    region() {
      const countryInfo =
        this.courierCountriesStates && this.courierCode && this.courierCountriesStates[this.courierCode]
          ? this.courierCountriesStates[this.courierCode].countryLists.find(({ code }) => code === this.countryCode)
          : null;
      return countryInfo ? countryInfo.region : '99';
    },
    memberId() {
      return this.memberInfo?.member_id ?? null;
    },
    shipToType() {
      return this.memberInfo?.ship_to_type ?? 1;
    },
    titleClass() {
      return {
        red: this.error,
        primary: !this.error && !this.success,
        success: this.success,
      };
    },
    confirmBtnLabel() {
      return this.error ? '閉じる' : this.modalConfirmBtnLabel;
    },
    disabled() {
      if (this.shipToTypeNo !== 5 && !this.showConfirm) {
        return true;
      }
      if (this.showConfirm) {
        return true;
      }
      return false;
    },
    disableBtn() {
      return this.computedCountryName || this.country ? false : true;
    },
    hasStates() {
      return this.courierStates ? this.courierStates?.map((x) => x)?.filter((x) => x)?.length > 0 : false;
    },
    manualShipToInputs() {
      return {
        shipping_terms: this.shippingTerm,
        third_party_acc_info: [this.thirdPartyAccInfo],
        ship_to_company_name: this.shipToCompanyName,
        member_courier_acc_no: this.shipTo ? this.shipTo.courier_account : null,
        courier_code: this.courierCode,
        delivery_type: this.deliveryType,
        country_code: this.countryCode,
        state_code: this.stateCode,
        city: this.city,
        zip_code: this.zipCode,
        addr1: this.addr1,
        addr2: this.addr2,
        tel: this.tel,
        insurance: this.insurance,
        insurance_price:
          this.insurance == 1 ? (this.insuranceCalculation1 ? this.insurancePriceByCourier : this.insurancePrice) : 0,
        insurance_price_type: this.insuranceCalculation2 ? 1 : 0,
        jpy_exchange_rate:
          (this.shipTo && this.shipTo.sent_to_courier_ship_api === 1 ? this.shipTo.jpy_exchange_rate : null) ||
          this.rate,
        insurance_range: this.percent,
        account_holder: this.accountHolder,
        ship_to_type: this.shipToTypeNo,
        manager_name: this.personInCharge,
        shipping_fee_payer: this.shippingFeePayer,
        region: this.region,
        shipping_fee_payer_type: this.shippingFeePayer,
        shipping_fee_paying_account: this.shippingFeePayerAcc,
        shipping_fee_payer_company_name: this.shippingFeePayerCompanyName,
        tax_payer_type: this.accountHolder,
        tax_payer_company_name: this.taxPayerCompanyName,
        tax_paying_account: this.taxPayerAccNo,
        vat_number: this.vatNumber,
        po_number_list: this.poNumberList,
        reference: this.reference,
        description: this.description,
      };
    },
    higherInsuranceLimit() {
      return this.courierCode == 1 && this.insurance === 1
        ? this.insuranceCalculation2
          ? this.insurancePrice > 50000
          : this.insurancePriceByCourier > 50000
        : false;
    },
  },
  watch: {
    countryCodeThirdParty(val) {
      this.thirdPartyAccInfo.account_holder_country_code = val;
    },
    insurance(val) {
      /**
       * 補償なしから補償ありに選択するとき
       * 初期時（初期t_packingに登録時）にinsurance_rangeが0にあっている場合
       * デフォルトのレートが100%にする
       */
      if (val === 1 && (this.insuranceCalculation1 || this.insuranceCalculation2)) {
        if (this.shipTo.insurance_range === 0 && this.shipTo.insurance === 0) {
          this.percent = 100;
        }
      } else this.percent = 0;
    },
    insuranceCalculation1(val) {
      if (val == this.insuranceCalculation2) {
        this.insuranceCalculation2 = !this.insuranceCalculation2;
        const oldVal = this.insurancePrice;
        this.insurancePrice =
          oldVal === 0
            ? this.shipTo.insurance_price_type === 1
              ? this.shipTo.insurance_price
              : this.courierCode == 1
                ? this.memberInfo?.total_price ?? 0
                : +(this.memberInfo?.total_price * this.rate.toFixed(0)) ?? 0
            : oldVal;
      }
    },
    insuranceCalculation2(val) {
      if (val == this.insuranceCalculation1) {
        this.insuranceCalculation1 = !this.insuranceCalculation1;
      }
    },
    async showModal(val) {
      if (val) {
        this.shipToTypeNo = this.shipToType ?? 1;
        await this.apiGetShipTo([
          this.memberInfo.sent_to_courier_ship_api === 1 ? 5 : this.shipToTypeNo,
          this.memberId,
          this.memberInfo.shipping_seq_number,
          this.memberInfo.matter_no_list,
        ]);
        this.updateAddr();
      }
      if (!val) {
        this.resetShipTo();
        this.resetData();
      }
    },
    async shipToTypeNo(val) {
      if (val && +this.memberInfo.sent_to_courier_ship_api === 0)
        await this.apiGetShipTo([
          val,
          this.memberId,
          this.memberInfo.shipping_seq_number,
          this.memberInfo.matter_no_list,
        ]);
      this.updateAddr();
    },
    thirdPartyAccInfo: {
      handler: function (val) {
        this.taxPayerAccNo = this.accountHolder == 1 && this.courierCode === 2 ? val.account : this.taxPayerAccNo;
        this.taxPayerCompanyName =
          this.accountHolder == 1 && this.courierCode === 2
            ? val.account_holder_company_name
            : this.taxPayerCompanyName;
        this.shippingFeePayerAcc =
          this.shippingFeePayer == 2 && this.courierCode === 2 ? val.account : this.shippingFeePayerAcc;
        this.shippingFeePayerCompanyName =
          this.shippingFeePayer == 2 && this.courierCode === 2
            ? val.account_holder_company_name
            : this.shippingFeePayerCompanyName;
      },
      deep: true,
    },
    async btnToggle(val, old) {
      if (val !== old && typeof val === 'undefined') {
        await this.apiGetShipTo([
          5,
          this.memberId,
          this.memberInfo.shipping_seq_number,
          this.memberInfo.matter_no_list,
        ]);
        this.updateAddr();
      }
    },
  },
  methods: {
    ...mapActions({
      apiGetShipTo: 'api/getShipTo',
      apiGetShipToTempChange: 'api/getShipToTempChange',
      resetShipTo: 'shukkaShoruiSakusei/reSetShipTo',
    }),
    resetData() {
      this.shippingTerm = '';
      this.thirdPartyAccInfo = [];
      this.shipToCompanyName = '';
      this.taxPayerAccNo = '';
      this.shippingFeePayerAcc = '';
      this.shippingFeePayerCompanyName = '';
      this.taxPayerCompanyName = '';
      this.vatNumber = '';
      this.poNumberList = '';
      this.reference = '';
      this.description = '';
      this.shippingFeePayer = 0;
      this.deliveryType = '1';
      this.country = null;
      this.state = null;
      this.city = '';
      this.zipCode = '';
      this.addr1 = '';
      this.addr2 = '';
      this.tel = '';
      this.personInCharge = '';
      this.insurance = null;
      this.insurancePrice = 0;
      this.accountHolder = null;
      this.validate = false;
      this.sm1 = 3;
      this.sm2 = 9;
      this.insuranceCalculation1 = true;
      this.insuranceCalculation2 = false;
      this.percent = 100;
      this.btnToggle = null;
    },
    async pasteAddr(shipTo = 5, once = false) {
      !once
        ? await this.apiGetShipTo([
            shipTo,
            this.memberId,
            this.memberInfo.shipping_seq_number,
            this.memberInfo.matter_no_list,
          ])
        : void 0;
      this.deliveryType = this.shipTo.delivery_type ? this.shipTo.delivery_type : '1';
      this.courierCode = this.shipTo.courier_code;
      this.shipToCompanyName = this.shipTo.ship_to_company_name;
      this.city = this.shipTo.ship_to_city;
      this.zipCode = this.shipTo.ship_to_zip_code;
      this.addr1 = this.shipTo.ship_to_address1;
      this.addr2 = this.shipTo.ship_to_address2;
      this.tel = this.shipTo.telephone_number;
      this.insurance = this.shipTo.insurance ?? 0;
      this.shippingFeePayer = this.shipTo.shipping_fee_payer_type;
      this.accountHolder = this.shipTo.account_holder;
      this.personInCharge =
        this.shipTo.sent_to_courier_ship_api === 1 ? this.memberInfo.manager_name : this.shipTo.name;
      this.shippingTerm = Number(this.memberInfo.shipping_terms);
      this.shippingFeePayerAcc = this.memberInfo.shipping_fee_payer === 0 ? 'Aucnet Acc' : this.shipTo.courier_account;
      this.shippingFeePayerCompanyName =
        this.memberInfo.shipping_fee_payer === 0 ? 'Aucnet Inc.' : this.shipTo.account_holder_company_name;
      this.taxPayerAccNo = this.shipTo.courier_account;
      this.taxPayerCompanyName = this.shipTo.account_holder_company_name;
      this.vatNumber = this.shipTo ? this.shipTo.vat_number : '';
      this.poNumberList = this.memberInfo.po_number_list;
      this.reference = this.memberInfo.reference;
      this.description = this.memberInfo.description;
      this.thirdPartyAccInfo = this.shipTo.third_party_account_info
        ? this.shipTo.third_party_account_info[0]
        : {
            account:
              this.shippingFeePayer === 2
                ? this.shippingFeePayerAcc
                : this.accountHolder == 1
                  ? this.taxPayerAccNo
                  : '',
            account_holder_company_name:
              this.shippingFeePayer === 2
                ? this.shippingFeePayerCompanyName
                : this.accountHolder == 1
                  ? this.taxPayerCompanyName
                  : '',
          };
      this.country = this.computedCountryName;
      this.state = this.computedStateName;
      // this.insurancePrice = this.shipTo.insurance_price;
    },
    updateAddr() {
      if (this.shipTo && this.shipToTypeNo !== 5) {
        this.loadInitialShipInfo();
        this.insurance_price_type = 0;
        this.percent = this.shipTo.insurance_range;
      } else if (this.shipTo && this.shipToTypeNo === 5) {
        this.loadInitialShipInfo();
        this.country = this.computedCountryName;
        this.state = this.computedStateName;
        this.accountHolder = this.shipTo.tax_payer_type ?? this.shipTo.account_holder;
        this.percent = this.shipTo.insurance_range;
        this.insuranceCalculation1 = this.shipTo.insurance_price_type === 0;
        this.insuranceCalculation2 = this.shipTo.insurance_price_type === 1;
      }
    },
    loadInitialShipInfo() {
      this.deliveryType = this.shipTo?.delivery_type ?? '1';
      this.courierCode = this.shipTo.courier_code;
      this.shipToCompanyName = this.shipTo.ship_to_company_name;
      this.city = this.shipTo.ship_to_city;
      this.zipCode = this.shipTo.ship_to_zip_code;
      this.addr1 = this.shipTo.ship_to_address1;
      this.addr2 = this.shipTo.ship_to_address2;
      this.tel = this.shipTo.telephone_number;
      this.insurance = this.shipTo.insurance ?? 0;
      this.shippingFeePayer = this.shipTo?.shipping_fee_payer_type ?? 0;
      this.accountHolder = this.shipTo.account_holder;
      this.personInCharge =
        this.shipTo.sent_to_courier_ship_api === 1 ? this.memberInfo.manager_name : this.shipTo.name;
      this.shippingTerm = Number(this.memberInfo.shipping_terms);
      this.shippingFeePayerAcc =
        this.memberInfo.shipping_fee_payer === 0
          ? 'Aucnet Acc'
          : // 送付先1,2,3,4の場合
            this.shipToTypeNo !== 5
            ? this.shipTo.courier_account
            : this.shipTo.shipping_fee_paying_account;
      this.shippingFeePayerCompanyName =
        this.memberInfo.shipping_fee_payer === 0
          ? 'Aucnet Inc.'
          : // 送付先1,2,3,4の場合
            this.shipToTypeNo !== 5
            ? this.shipTo.account_holder_company_name ?? this.shipTo.shipping_fee_payer_company_name
            : this.shipTo.shipping_fee_payer_company_name;
      this.taxPayerAccNo =
        // 送付先1,2,3,4の場合
        this.shipToTypeNo !== 5 ? this.shipTo.courier_account : this.shipTo.tax_paying_account;
      this.taxPayerCompanyName =
        // 送付先1,2,3,4の場合
        this.shipToTypeNo !== 5
          ? this.shipTo.account_holder_company_name ?? this.shipTo.tax_payer_company_name
          : this.shipTo.tax_payer_company_name;
      this.vatNumber = this.shipTo ? this.shipTo.vat_number : '';
      this.poNumberList = this.memberInfo.po_number_list;
      this.reference = this.memberInfo.reference;
      this.insurancePrice = this.memberInfo.insurance_price;
      this.description = this.shipToTypeNo !== 5 ? this.descriptionString : this.memberInfo.description;
      this.thirdPartyAccInfo = this.shipTo.third_party_account_info
        ? this.shipTo.third_party_account_info[0]
        : {
            account:
              this.shippingFeePayer === 2
                ? this.shippingFeePayerAcc
                : this.accountHolder == 1
                  ? this.taxPayerAccNo
                  : '',
            account_holder_company_name:
              this.shippingFeePayer === 2
                ? this.shippingFeePayerCompanyName
                : this.accountHolder == 1
                  ? this.taxPayerCompanyName
                  : '',
          };
    },
    changeCourier(courierCode) {
      this.state = null;
      this.description =
        this.shipToTypeNo === 5 && this.courierCode == this.shipTo.courier_code
          ? this.memberInfo.description
          : this.descriptionString;
      this.shippingFeePayer = this.shipTo.shipping_fee_payer_type;
      this.shippingFeePayerAcc =
        this.memberInfo.courier != courierCode ? null : this.shipTo.shipping_fee_paying_account;
      this.shippingFeePayerCompanyName =
        this.memberInfo.courier != courierCode ? null : this.shipTo.shipping_fee_payer_company_name;
      this.accountHolder = this.shipTo.tax_payer_type ?? this.shipTo.account_holder;
      this.taxPayerAccNo = this.memberInfo.courier != courierCode ? null : this.shipTo.tax_paying_account;
      this.taxPayerCompanyName = this.memberInfo.courier != courierCode ? null : this.shipTo.tax_payer_company_name;
    },
    changeShippingFeePayer() {
      if (this.shipToTypeNo === 5) {
        if (this.shippingFeePayer === 0) {
          this.shippingFeePayerAcc = 'Aucnet Acc';
          this.shippingFeePayerCompanyName = 'Aucnet Inc.';
        } else if (this.shippingFeePayer === 2) {
          this.shippingFeePayerAcc = this.thirdPartyAccInfo.account;
          this.shippingFeePayerCompanyName = this.thirdPartyAccInfo.account_holder_company_name;
        } else {
          this.shippingFeePayerAcc =
            this.memberInfo.courier != this.courierCode
              ? null
              : this.shippingFeePayer !== this.shipTo.shipping_fee_payer_type
                ? null
                : this.shipTo.shipping_fee_paying_account;
          this.shippingFeePayerCompanyName =
            this.memberInfo.courier != this.courierCode
              ? null
              : this.shippingFeePayer !== this.shipTo.shipping_fee_payer_type
                ? null
                : this.shipTo.shipping_fee_payer_company_name;
        }
      } else if (this.shippingFeePayer === 0) {
        this.shippingFeePayerAcc = 'AUCNET ACC';
        this.shippingFeePayerCompanyName = 'AUCNET Inc.';
      } else if (this.shippingFeePayer === 1 && this.shippingFeePayer - 1 === this.shipTo.account_holder) {
        this.shippingFeePayerAcc = this.shipTo.courier_account;
        this.shippingFeePayerCompanyName = this.shipTo.ship_to_company_name;
      } else if (this.shippingFeePayer === 2 && this.shippingFeePayer - 1 === this.shipTo.account_holder) {
        this.shippingFeePayerAcc = this.shipTo.courier_account;
        this.shippingFeePayerCompanyName = this.shipTo.account_holder_company_name;
      } else {
        this.shippingFeePayerAcc = null;
        this.shippingFeePayerCompanyName = null;
      }
    },
    changeTaxPayer() {
      if (this.shipToTypeNo === 5) {
        this.taxPayerAccNo =
          this.memberInfo.courier != this.courierCode
            ? null
            : this.accountHolder !== this.shipTo.tax_payer_type
              ? null
              : this.shipTo.tax_paying_account;
        this.taxPayerCompanyName =
          this.memberInfo.courier != this.courierCode
            ? null
            : this.accountHolder !== this.shipTo.tax_payer_type
              ? null
              : this.shipTo.tax_payer_company_name;
      } else if (this.accountHolder === 0) {
        this.taxPayerCompanyName = this.shipTo.ship_to_company_name;
      } else if (this.accountHolder === 1) {
        this.taxPayerCompanyName = this.shipTo.account_holder_company_name;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    /**
     * 「変更」ボタンを押すとAPIで変更を保存する
     */
    handleChange() {
      const valid = this.$refs.form.validate();

      // shipToTypeNo=1,2,3,4の場合に$emitする
      // shipToTypeNo=5はvalid=trueの場合に$emitする
      if (valid || this.shipToTypeNo !== 5) {
        this.$emit(
          'click:confirm',
          // sameShipToAndSameShippingFeePayer,
          false,
          this.shipToTypeNo,
          this.manualShipToInputs,
          this.memberId,
          this.memberInfo.shipping_seq_number,
          this.memberInfo.matter_no_list,
          this.shippingFeePayer,
          this.shippingFeePayerAcc,
          this.shippingFeePayerCompanyName,
          this.taxPayerAccNo,
          this.taxPayerCompanyName,
          this.description,
        );
      }
    },
    handleSend() {
      this.$emit('click:send', this.memberInfo, this.manualShipToInputs);
    },
    setInsurancePrice(input) {
      this.insurancePrice = +input;
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}

.fixed-header {
  position: sticky;
  z-index: 10;
  top: 0px;
}
</style>
